import { defineNuxtRouteMiddleware, useAsyncData } from 'nuxt/app';

export default defineNuxtRouteMiddleware((to, from) => {
    // console.log(to);
    // const { route, redirect } = context;
    // if (route.path.match(/^\/articles\/\d+\/\w+$/)) {
    //     return;
    // }
    // if (route.path.match(/^\/test$/)) {
    //     return redirect('/test');
    // }
});
